import React, { useRef, useContext, useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContentWrapper from "styles/contentWrapper"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 4rem 0;
  padding-top: 7rem;
  .section-title {
    margin-bottom: 3rem;
    padding: 0 1rem;
  }
  background-image: url(https://s3.amazonaws.com/img.riminder.net/spot-advantages1.svg), url(https://s3.amazonaws.com/img.riminder.net/spot-advantages2.svg), url(https://s3.amazonaws.com/img.riminder.net/spot-advantages3.svg), url(https://s3.amazonaws.com/img.riminder.net/spot-advantages4.svg), url(https://s3.amazonaws.com/img.riminder.net/spot-advantages5.svg);
  background-repeat: no-repeat;
  background-position: 23% 80%, 87% 113%, 68% 41%, 97% 101%, 94% 33%;
  background-size: 23.7rem 29.1rem, 16rem 22.6rem, 2.8rem 3.3rem, 6.5rem 10.3rem, 3.15rem 3.9rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
`

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .item {
    margin: 2rem;
    max-width: 300px;
    min-width: 300px;
    .logo {
      max-width: 5rem;
    }
  }
  .item {
    display: flex;
    align-items: flex-end;
    .logo {
      width: 6rem;
      height: auto;
      margin-right: 1.5rem;
    }
    &__content {
      &-number {
        font-size: 3rem;
        line-height: 6rem;
        font-weight: 700;
      }
      &-subtitle {
        margin-bottom: 1rem;
      }
      &-description {
        font-size: 1.3rem;
        font-weight: 600;
        max-width: 10rem;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    align-items: start;
    .item {
      margin: 0;
      max-width: unset;
      min-width: unset;
      .logo {
        max-width: unset;
      }
    }
    .item:nth-of-type(1) {
      justify-self: start;
    }
    .item:nth-of-type(2) {
      margin-top: 5rem;
      justify-self: center;
    }
    .item:nth-of-type(3) {
      margin-top: 10rem;
      justify-self: end;
    }
  }
`

const Advantages = ({ content }) => {
  const { exports } = content[0].node
  const { items }  = exports
  return (
    <StyledSection id="advantages">
      <StyledContentWrapper>
        <StyledInner>
          {items.map(({ number, logo, subtitle, description }, key) => {
            const image = getImage(logo)
            return (
              <div className="item" key={key}>
                <GatsbyImage className="logo" alt={subtitle} image={image} />
                <div className="item__content">
                  <div className="item__content-number">{number}</div>
                  <div className="item__content-subtitle">{subtitle}</div>
                  <div className="item__content-description">{description}</div>
                </div>
              </div>
            )
          })}
        </StyledInner>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Advantages